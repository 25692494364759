<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.CODE')">
                <b-form-input
                  name="code"
                  v-model="filters.code.value"
                  :placeholder="t('GLOBAL.CODE')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="item.name"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.CODE')">
              <b-form-input
                name="code"
                v-model="item.code"
                :placeholder="t('GLOBAL.CODE')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.TITLE')">
              <b-form-input
                name="title"
                v-model="item.title"
                :placeholder="t('GLOBAL.TITLE')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.CONTENT')">
              <TinyEditor v-model="item.content" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.TEMPLATE')">
              <v-select2
                v-model="item.templateId"
                :text="item.template ? item.template.name : ''"
                endPoint="notificationTemplates"
                :placeholder="t('GLOBAL.TEMPLATE')"
                sort="name"
              ></v-select2>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.TYPE')">
              <vue-select
                v-model="item.type"
                label="text"
                :options="[
                  { text: t('GLOBAL.EMAIL'), value: 1 },
                  { text: t('GLOBAL.SMS'), value: 2 }
                ]"
                :multiple="false"
              >
              </vue-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-ModuleGroup
export default {
  name: "notifications",
  data() {
    return {
      item: {},
      moduleData: {
        name: "notifications",
        primaryKey: "id",
        table: {
          sortBy: "name",
          sortDesc: false,
          graphqlQuery: `
							id,name,code,template{name}
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.CODE"), value: "code" },
            { text: this.t("GLOBAL.TEMPLATE"), value: "template.name" }
          ],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            code: {
              type: "string",
              searchBy: "contains",
              value: null
            }
          }
        },
        form: {
          maxWidth: "80%"
        }
      }
    };
  }
};
</script>
