var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModuleComponent',{attrs:{"module-data":_vm.moduleData},on:{"changed":function($event){_vm.item = $event}},scopedSlots:_vm._u([{key:"tableFilters",fn:function(ref){
var filters = ref.filters;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.NAME')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.NAME')},model:{value:(filters.name.value),callback:function ($$v) {_vm.$set(filters.name, "value", $$v)},expression:"filters.name.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CODE')}},[_c('b-form-input',{attrs:{"name":"code","placeholder":_vm.t('GLOBAL.CODE')},model:{value:(filters.code.value),callback:function ($$v) {_vm.$set(filters.code, "value", $$v)},expression:"filters.code.value"}})],1)],1)],1)],1)]}},{key:"form",fn:function(ref){
var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.NAME')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.NAME')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CODE')}},[_c('b-form-input',{attrs:{"name":"code","placeholder":_vm.t('GLOBAL.CODE')},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.TITLE')}},[_c('b-form-input',{attrs:{"name":"title","placeholder":_vm.t('GLOBAL.TITLE')},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CONTENT')}},[_c('TinyEditor',{model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.TEMPLATE')}},[_c('v-select2',{attrs:{"text":item.template ? item.template.name : '',"endPoint":"notificationTemplates","placeholder":_vm.t('GLOBAL.TEMPLATE'),"sort":"name"},model:{value:(item.templateId),callback:function ($$v) {_vm.$set(item, "templateId", $$v)},expression:"item.templateId"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.TYPE')}},[_c('vue-select',{attrs:{"label":"text","options":[
                { text: _vm.t('GLOBAL.EMAIL'), value: 1 },
                { text: _vm.t('GLOBAL.SMS'), value: 2 }
              ],"multiple":false},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }